<template>
  <div class="body_wrap">

<!-- backtotop - start -->
<div class="backtotop">
  <a href="#" class="scroll">
    <i class="far fa-arrow-up"></i>
  </a>
</div>
<!-- backtotop - end -->

<!-- preloader - start -->
<!-- <div id="preloader"></div> -->
<!-- preloader - end -->

<!-- header_section - start
================================================== -->
      <header class="header_section">
        <div class="header_middle">
          <div class="container">
            <div class="row align-items-center">
              <div class="col col-lg-3 col-5">
                <div class="brand_logo">
                  <a class="brand_link" href="/">
                    <img src="./assets/logo.png">
                  </a>
                </div>
              </div>
              <div class="col col-lg-6 col-2">
                <nav class="main_menu navbar navbar-expand-lg">
                  <div class="main_menu_inner collapse navbar-collapse justify-content-center" id="main_menu_dropdown">
                    <ul class="main_menu_list ul_li">
                      <!-- <li>
                        <a href="/">Home</a>
                      </li> -->
                      <!-- <li>
                        <a href="/register">register</a>
                      </li> -->
                      <!-- <li class="active"><a href="/register">Sign In</a></li>
                      <li><a style="cursor: pointer;" @click="tiaozhuanAccount()">Shopping Cart</a></li> -->
                      <li><a href="/#1">HOT ITEMS</a></li>
                      <li><a href="/#2">NEW ARRIVALS</a></li>
                      <li><a href="/#3">BEST SELLER</a></li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div class="col col-lg-3 col-5">
                <ul class="header_icons_group ul_li_right">
                  <li>
                    <button class="mobile_menu_btn navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-controls="main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <i class="fal fa-bars"></i>
                    </button>
                  </li>
                  <li><a style="cursor: pointer;" @click="tiaozhuanAccount()"><i class="fa-thin fa-cart-shopping"></i></a></li>
                  <li><a style="cursor: pointer;" @click="tiaozhuanAccount2()"><i class="fa-regular fa-user"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="header_bottom">
          <div class="container">
            <div class="row align-items-center" style="padding:10px 0px;">
              <div class="col col-md-10 col-10">
                <div class="allcategories_dropdown">
                  <h2 class="title_text" style="color:#fff;"><i class="fa-duotone fa-fire"></i> Technology e-commerce, worry-free shopping!</h2>
                </div>
              </div>

              <div class="col col-md-2 col-2">
                <!-- <a @click="tiaozhuanAccount()">
                <button type="button" class="cart_btn">
                  <span class="cart_icon">
                    <i class="icon icon-ShoppingCart"></i>
                  </span>
                </button>
              </a> -->
              </div>
            </div>
          </div>
        </div>
      </header>
<!-- header_section - end
================================================== -->

<!-- main body - start
================================================== -->
<main>

  <!-- sidebar cart - start
  ================================================== -->
  <div class="sidebar-menu-wrapper">
    <div class="cart_sidebar">
      <button type="button" class="close_btn"><i class="fal fa-times"></i></button>

      <ul class="cart_items_list ul_li_block mb_30 clearfix">
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_1.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_2.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_3.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
      </ul>

      <ul class="total_price ul_li_block mb_30 clearfix">
        <li>
          <span>Subtotal:</span>
          <span>MXN$90</span>
        </li>
        <li>
          <span>Vat 5%:</span>
          <span>MXN$4.5</span>
        </li>
        <li>
          <span>Discount 20%:</span>
          <span>- MXN$18.9</span>
        </li>
        <li>
          <span>Total:</span>
          <span>MXN$75.6</span>
        </li>
      </ul>

      <ul class="btns_group ul_li_block clearfix">
        <li><a class="btn btn_primary" href="/cart">View Cart</a></li>
        <li><a class="btn btn_secondary" href="checkout.html">Checkout</a></li>
      </ul>
    </div>

    <div class="cart_overlay"></div>
  </div>
  <!-- sidebar cart - end
  ================================================== -->

  <!-- breadcrumb_section - start
  ================================================== -->
  <div class="breadcrumb_section">
    <div class="container">
      <ul class="breadcrumb_nav ul_li">
        <li><a href="/">Home</a></li>
        <li>Register</li>
      </ul>
    </div>
  </div>
  <!-- breadcrumb_section - end
  ================================================== -->

  <!-- register_section - start
  ================================================== -->
  <section class="register_section section_space">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <ul class="nav register_tabnav ul_li_center" role="tablist">
            <li role="presentation">
              <button class="active" id="sign" data-bs-toggle="tab" data-bs-target="#signin_tab" type="button" role="tab" aria-controls="signin_tab" aria-selected="true">
                Sign In
              </button>
            </li>
            <li role="presentation">
              <button data-bs-toggle="tab" data-bs-target="#signup_tab" type="button" role="tab" aria-controls="signup_tab" aria-selected="false">
                Register
              </button>
            </li>
          </ul>
          <div class="register_wrap tab-content">
            <div class="tab-pane fade show active" id="signin_tab" role="tabpanel">
              <form  @submit.prevent="denglu()">
                <div class="form_item_wrap">
                  <h3 class="input_title">User Name/Email*</h3>
                  <div class="form_item">
                    <label for="username_input"><i class="fas fa-user"></i></label>
                    <input v-model="logEmail" id="username_input" type="text" name="username" placeholder="User Name">
                    <span>Ex:black-level help text here.</span>
                  </div>
                </div>

                <div class="form_item_wrap">
                  <h3 class="input_title">Password*</h3>
                  <div class="form_item">
                    <label for="password_input"><i class="fas fa-lock"></i></label>
                    <input v-model="logPassword" id="password_input" type="password" name="password" placeholder="Password">
                  </div>
                </div>

                <!-- <div class="forget_pass_wrap align-items-center justify-content-between">
                  <div class="checkbox_item m-0">
                    <input id="remember_checkbox" type="checkbox">
                    <label for="remember_checkbox">Remember Me</label>
                  </div>
                  <div class="forget_pass">
                    <a href="#!">Forget Password?</a>
                  </div>
                </div> -->

                <div class="text-start">
                  <button type="submit" class="btn btn_secondary">Sign In Now</button>
                </div>
              </form>
            </div>

            <div class="tab-pane fade" id="signup_tab" role="tabpanel">
              <form  @submit.prevent="zhuce()">
                <div class="form_item_wrap">
                    <h3 class="input_title">Email*</h3>
                    <div class="form_item">
                        <label for="email_input"><i class="fas fa-envelope"></i></label>
                        <input v-model="email" id="email_input" type="email" name="email" placeholder="Email">
                    </div>
                </div>

                <div class="form_item_wrap">
                  <h3 class="input_title">Password*</h3>
                  <div class="form_item">
                    <label for="password_input2"><i class="fas fa-lock"></i></label>
                    <input v-model="Password" id="password_input2" type="password" name="Password" placeholder="Password">
                  </div>
                </div>

                <div class="form_item_wrap">
                  <h3 class="input_title">Password2*</h3>
                  <div class="form_item">
                    <label for="password_input2"><i class="fas fa-lock"></i></label>
                    <input v-model="Password2" id="password_input2" type="password" name="Password2" placeholder="Password">
                  </div>
                </div>

                <div class="text-start">
                  <button type="submit" class="btn btn_secondary">Register Now</button>
                </div>
              </form>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </section>
  <!-- register_section - end
  ================================================== -->

  <!-- newsletter_section - start
  ================================================== -->
  <section class="newsletter_section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col col-lg-6">
          <h2 class="newsletter_title text-white">
            <i class="icofont-paper-plane"></i>
            Sign Up for Newsletter
          </h2>
        </div>
        <div class="col col-lg-6">
          <form action="#!">
            <div class="newsletter_form">
              <input type="email" name="email" placeholder="Enter your email address">
              <button type="submit" class="btn btn_danger">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- newsletter_section - end
  ================================================== -->

</main>
<!-- main body - end
================================================== -->

<!-- footer_section - start
================================================== -->
<footer class="footer_section">
        <div class="footer_widget_area">
          <div class="container">
            <div class="row">
              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Quick Links</h3>
                  <ul class="ul_li_block">
                    <li><a href="/register">Sign In</a></li>
                    <!-- <li><a href="/register">Sign Up</a></li> -->
                  </ul>
                </div>
              </div>

              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Custom area</h3>
                  <ul class="ul_li_block">
                    <li><a href="/account">My Account</a></li>
                    <li><a href="/cart">My Cart</a></li>
                  </ul>
                </div>
              </div>

              <div class="col col-lg-8 col-md-6 col-sm-12">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">contact us</h3>
                  <ul class="ul_li_block">
                    <li>Company Name： INFORMATICA GH SOLUCIONES, S.A. DE C.V.</li>
                    <li>E-mail: hungrybulo@outlook.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
<!-- footer_section - end
================================================== -->

</div>
</template>

<script>
import {
  apiFrontRegisterEmail, apiFrontLoginEmail
} from '@/api/goods'
export default {
  data() {
    return {
      email: '',
      Password: '',
      Password2: '',
      logEmail: '',
      logPassword: ''
    }
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.$router.push('/account')
    }
  },
  methods: {
    tiaozhuanAccount() {
      if (localStorage.getItem('token')) {
        this.$router.push('/cart')
      } else {
        this.$router.push('/register')
        const h = this.$createElement;
        this.$notify({
          title: 'please log in',
          message: h('i', { style: 'color: red'}, 'You have not logged into your account')
        });
      }
    },
    tiaozhuanAccount2() {
      if (localStorage.getItem('token')) {
        this.$router.push('/account')
      } else {
        this.$router.push('/register')
        const h = this.$createElement;
        this.$notify({
          title: 'please log in',
          message: h('i', { style: 'color: red'}, 'You have not logged into your account')
        });
      }
    },
    zhuce() {
      var data = {
        email: this.email,
        password: this.Password,
        password2: this.Password2
      }
      console.log(this.Password);
      apiFrontRegisterEmail(data).then(res => {
        console.log(res)
        this.logEmail = this.email
        this.logPassword = this.Password
        const sign = document.getElementById("sign");
        sign.click()
        this.$message.success('Account Registered Successfully')
      }).catch((err) => {
        console.log(err)
        this.$message.error('Failed to Register Account')
      })
    },
    denglu() {
      var data = {
        account: this.logEmail,
        password: this.logPassword
      }
      apiFrontLoginEmail(data).then(res => {
        console.log(res)
        localStorage.setItem('token', res.data.token);
        this.$message.success('Login Successful')
        this.$router.push('/')
      }).catch((err) => {
        console.log(err)
        // this.$message.error('Login Failed')
      })
    }
  }
}
</script>

<style>

</style>